import React from 'react';
import {FaceLivenessDetector} from '@aws-amplify/ui-react-liveness';
import {Loader, ThemeProvider} from '@aws-amplify/ui-react';

interface ResultProps {
    Confidence: number;
    Status: "SUCCEEDED";
}

const hostname = 'https://dkjcjwdncl.execute-api.ap-northeast-1.amazonaws.com/test';

export function App() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [sessionId, setSessionId] = React.useState<string | null>(null);
    const [result, setResult] = React.useState<ResultProps | null>(null);

    const fetchCreateLiveness = async () => {
        const url = hostname + '/session';

        const data = await fetch(url, {
            headers: {
                'Content-Type': 'text/plain'
            },
        }).then((res) => {
            return res.text();
        }).then((data) => {
            const session = data.replace(/["']/g, "")
            console.log(session);
            return session;
        })

        setSessionId(data);
        setLoading(false);
    };

    React.useEffect(() => {
        fetchCreateLiveness();
    }, []);

    const handleAnalysisComplete = async () => {

        console.log('Analysis complete')
        // return new Promise(resolve => {} ).then(() => {});
        /*
         * This should be replaced with a real call to your own backend API
         */
        const response = await fetch(
            `${hostname}/result/${sessionId}`
        );
        const data = await response.json();
        setResult(data);
        console.log(data);

        /*
         * Note: The isLive flag is not returned from the GetFaceLivenessSession API
         * This should be returned from your backend based on the score that you
         * get in response. Based on the return value of your API you can determine what to render next.
         * Any next steps from an authorization perspective should happen in your backend and you should not rely
         * on this value for any auth related decisions.
         */
    };

    console.log(sessionId);
    return (
        <ThemeProvider>
            {loading ? (
                <Loader/>
            ) : (
                <div>
                    {
                        result ?
                            <div style={{
                                width: "100wh",
                                height: "100vh",
                                fontSize: '38px',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <div>
                                    {result?.Status}
                                    <br/>
                                    liveness = {result?.Confidence}
                                </div>
                            </div>
                            :
                            <FaceLivenessDetector
                                key={sessionId as unknown as string}
                                sessionId={sessionId as unknown as string}
                                region="ap-northeast-1"
                                onAnalysisComplete={handleAnalysisComplete}
                                onUserCancel={() => {
                                    console.log('User cancelled');
                                    fetchCreateLiveness();
                                }}
                                disableInstructionScreen={true}
                            />


                    }
                </div>
            )}
        </ThemeProvider>
    );
}

export default App;